'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LiferayMobileClient = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _client = require('./client');

var _defaultDeviceInfo = require('./plugins/defaultDeviceInfo');

var _Constants = require('./Constants');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LiferayMobileClient = exports.LiferayMobileClient = function (_LiferayClient) {
  _inherits(LiferayMobileClient, _LiferayClient);

  function LiferayMobileClient(o) {
    _classCallCheck(this, LiferayMobileClient);

    var _this = _possibleConstructorReturn(this, (LiferayMobileClient.__proto__ || Object.getPrototypeOf(LiferayMobileClient)).call(this, _extends({}, _Constants.DEFAULT_MOBILE_OPTIONS, o)));

    _this.getDeviceInfo = function () {
      return _this.deviceInfoProvider.getDeviceInfo();
    };

    _this.getInstallation = function () {
      return _this.installation;
    };

    _this.isAutoInstall = function () {
      return _this.options.autoInstall || _this.autoInstall;
    };

    _this.isAutoInstallPush = function () {
      return _this.options.autoInstallPush || _this.autoInstallPush;
    };

    _this.afterInstall = function () {
      return _this.installPromise || _this.install();
    };

    _this.install = function () {
      return _this.afterInit().then(function () {
        if (_this.getAppConfig()) {
          if (!_this.installPromise) {
            _this.installPromise = _this._install();
          }
          return _this.installPromise;
        } else {
          return Promise.reject(new Error('AppConfig missing'));
        }
      });
    };

    _this.invokeMobile = function (method, data) {
      return _this.afterInit().then(_this.afterInstall).then(function () {
        return _this._invokeMobile(method, data);
      });
    };

    _this.isPushRegistered = function () {
      var installation = _this.getInstallation();
      return !!(installation && installation.pushToken);
    };

    _this._loadConfig = function () {
      return Promise.all([_this.store.getItem('autoInstallPush'), _this.store.getItem('installation')]).then(function (values) {
        _this.autoInstallPush = values[0];
        _this.installation = values[1] && JSON.parse(values[1]);
      });
    };

    _this._onStateChanged = function (newState) {
      if (newState === _Constants.STATE.LOGGED_IN) {
        if (_this.isAutoInstall()) {
          _this.install();
        }
      }
      _this.logger.logInfo('newState --> ' + newState);
    };

    _this.deviceInfoProvider = o.deviceInfoProvider || new _defaultDeviceInfo.DefaultDeviceInfo();
    _this.pushProvider = o.pushProvider || null;
    return _this;
  }

  _createClass(LiferayMobileClient, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      if (!this.mobileInitPromise) {
        this.logger.logInfo('Init mobile liferay client', this.options);
        this.appConfig = null;

        this.mobileInitPromise = this._loadConfig().then(this.deviceInfoProvider.getDeviceReadyPromise).then(function () {
          return _this2.pushProvider && _this2.pushProvider.loadPushToken() || Promise.resolve();
        }).then(function () {
          return _get(LiferayMobileClient.prototype.__proto__ || Object.getPrototypeOf(LiferayMobileClient.prototype), 'init', _this2).call(_this2);
        }).catch(this.logger.logError);
      }
      return this.mobileInitPromise;
    }
  }, {
    key: 'getRequestParams',
    value: function getRequestParams() {
      var appConfig = this.getAppConfig();
      return appConfig && {
        deviceUuid: this.getDeviceInfo().deviceUuid,
        variantUuid: appConfig.variantUuid,
        variantSecret: appConfig.variantSecret,
        variantVersionKey: appConfig.variantVersionKey,
        accountUid: this.installation && this.installation.accountUid //installation is loaded by _loadConfig()
      };
    }
  }, {
    key: 'getAppConfig',
    value: function getAppConfig() {
      if (!this.appConfig) {
        this.appConfig = this.options.deviceFamily[this.getDeviceInfo().family.toLowerCase()];
        if (this.appConfig && this.appConfig.variantUuid && this.appConfig.variantUuid.length > 0) {
          this.appConfig.variantVersionKey = this.appConfig.variantVersionKey || this.options.variantVersionKey || navigator.appBuildVersion;
        } else {
          this.logger.logError('No valid variant config for device family: ' + this.getDeviceInfo().family.toLowerCase() + ' config: ' + JSON.stringify(this.options.deviceFamily));
          this.appConfig = null;
        }
      }
      return this.appConfig;
    }
  }, {
    key: 'reInstall',
    value: function reInstall() {
      this.installPromise = null;
      return this.install();
    }
  }, {
    key: 'uninstall',
    value: function uninstall() {
      var params = this.getRequestParams();
      if (params) {
        this.invoke(this.options.unregisterMethod, params).then(this._saveInstallation.bind(this, null));
      } else {
        this.logger.logError('cannot uninstall from backend without device');
      }
    }
  }, {
    key: 'setAutoInstall',
    value: function setAutoInstall(value) {
      this.autoInstall = value;
      this.store.setItem('autoInstall', value);
    }
  }, {
    key: 'setAutoInstallPush',
    value: function setAutoInstallPush(value) {
      this.autoInstallPush = value;
      this.store.setItem('autoInstallPush', value);
    }
  }, {
    key: '_install',
    value: function _install() {
      var _this3 = this;

      return this._requestInstall().then(function (i) {
        return _this3._saveInstallation(i);
      }, function () {
        _this3.installPromise = null;
        setTimeout(function () {
          return _this3.install();
        }, 5000);
      });
    }
  }, {
    key: '_invokeMobile',
    value: function _invokeMobile(method, data) {
      var params = _extends({}, this.getRequestParams(), data);

      var headers = {
        variant: params.variantUuid,
        'variant-secret': params.variantSecret,
        account: params.accountUid
      };
      return this.invoke(method, params, headers);
    }
  }, {
    key: '_requestInstall',
    value: function _requestInstall() {
      this.logger.logInfo('_requestInstall');

      var params = _extends({}, this.getDeviceInfo(), this.getRequestParams());

      if (this.isAutoInstallPush()) {
        this._requestPush();
        params = _extends({}, params, {
          pushToken: this.pushProvider.getPushToken()
        });
      }

      this.logger.logInfo('invoke installation: ', params);
      var headers = {
        variant: params.variantUuid,
        'variant-secret': params.variantSecret,
        account: params.accountUid
      };
      return this.invoke(this.options.registerMethod, params, headers);
    }
  }, {
    key: '_requestPush',
    value: function _requestPush() {
      var pushConfig = this.options.deviceFamily;
      pushConfig.windows = pushConfig.windows || pushConfig.wp8;

      this.logger.logInfo('requestPush');
      if (this.pushProvider.isPushAlreadyRegistered()) {
        this.logger.logInfo('pushAlreadyRegistered');
        return;
      }
      this.pushProvider.init(pushConfig);
    }
  }, {
    key: '_logout',
    value: function _logout() {
      this._removeInstallation();
      _get(LiferayMobileClient.prototype.__proto__ || Object.getPrototypeOf(LiferayMobileClient.prototype), '_logout', this).call(this);
    }
  }, {
    key: '_saveInstallation',
    value: function _saveInstallation(installation) {
      this.logger.logInfo('installation success', installation);
      this.store.setObject('installation', installation);
      this.installation = installation;
      this.trigger(_Constants.EVENTS.INSTALL, installation);
    }
  }, {
    key: '_removeInstallation',
    value: function _removeInstallation() {
      this.installPromise = null;
      this.installation = null;
      return this.store.setObject('installation', null);
    }
  }]);

  return LiferayMobileClient;
}(_client.LiferayClient);