/**
 * User: n.gorte
 * Date: 26.03.2018
 * Time: 17:35
 */
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DefaultLogger = exports.DefaultLogger = function () {
    function DefaultLogger() {
        _classCallCheck(this, DefaultLogger);
    }

    _createClass(DefaultLogger, [{
        key: 'logInfo',
        value: function logInfo() {
            console.info.apply(console, arguments);
        }
    }, {
        key: 'logDebug',
        value: function logDebug() {
            console.debug.apply(console, arguments);
        }
    }, {
        key: 'logError',
        value: function logError() {
            console.error.apply(console, arguments);
        }
    }, {
        key: 'logWarn',
        value: function logWarn() {
            console.warn.apply(console, arguments);
        }
    }]);

    return DefaultLogger;
}();