/**
 * User: Carsten Hibbeler
 * Date: 19.03.14
 * Time: 16:28
 */

'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.LiferayClient = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _base = require('base-64');

var _base2 = _interopRequireDefault(_base);

var _errorLogging = require('./error-logging');

var _defaultLogger = require('./plugins/defaultLogger');

var _defaultStorage = require('./plugins/defaultStorage');

var _Constants = require('./Constants');

var _utils = require('./utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LiferayClient = exports.LiferayClient = function () {
    function LiferayClient(o) {
        var _this = this;

        _classCallCheck(this, LiferayClient);

        this.afterInit = function () {
            return _this.initPromise || _this.init();
        };

        this.getAccessToken = function () {
            return _this.afterInit().then(function () {
                return _this._getAccessToken();
            });
        };

        this.getState = function () {
            return _this.activeState;
        };

        this.getUser = function () {
            return _this.session && _this.session.user;
        };

        this.isGuestUser = function () {
            return _this.getUser() && _this.getUser().isGuest;
        };

        this.getServerUrl = function () {
            return _this.options.url;
        };

        this.invoke = function (path, data, headers) {
            return _this.afterInit().then(function () {
                return _this._invoke(path, data, headers);
            });
        };

        this.login = function (username, password) {
            return _this.afterInit().then(function () {
                return _this._login(username, password);
            });
        };

        this.logout = function () {
            return _this.afterInit().then(function () {
                return _this._logout();
            }).then(function (res) {
                return _this.trigger(_Constants.EVENTS.LOGOUT, res);
            }).catch(function (e) {
                return _this.logger.logError(e);
            });
        };

        this._foundLRSessionCookie = function () {
            return document && document.cookie.indexOf('LFR_SESSION_STATE') > -1;
        };

        this._getSession = function () {
            return _this.session;
        };

        this._onStateChanged = function (newState, prevState) {
            return _this.logger.logInfo('State changes ' + prevState + ' --> ' + newState);
        };

        this._getAuthToken = function () {
            return window && window.Liferay && window.Liferay.authToken;
        };

        this.store = o.store || new _defaultStorage.DefaultStorage();
        this.logger = o.logger || new _defaultLogger.DefaultLogger();
        this.options = this._initOptions(o);

        this.activeState = _Constants.STATE.INIT;
        this.events = {};

        this.logConsoleErrors = _errorLogging.ErrorLogger.logConsoleErrors.bind(this);
        this.buildStackTrace = _errorLogging.ErrorLogger.buildStackTrace.bind(this);
        this.sendEvent = _errorLogging.ErrorLogger.sendEvent.bind(this);
    }

    _createClass(LiferayClient, [{
        key: '_initOptions',
        value: function _initOptions(o) {
            if (!/^https?:\/\//i.test(o.url)) {
                this.logger.logError('Missing protocol in ' + o.url);
            }
            if (/^http?:\/\//i.test(o.url)) {
                this.logger.logWarn('DON\'T USE HTTP FOR PRODUCTION');
            }
            //TODO validate init options: sessionSharing with useBasicAuth is invalid

            return _extends({}, _Constants.DEFAULT_OPTIONS, o);
        }
    }, {
        key: 'init',
        value: function init() {
            var _this2 = this;

            if (!this.initPromise) {
                this.logger.logInfo('Init liferay client', this.options);
                this.initPromise = this.store.getObject(_Constants.SESSION_KEY).then(function (previousSession) {
                    return _this2._validatePrevSession(previousSession);
                }, function () {
                    return _this2._validatePrevSession.bind(_this2, null);
                }).then(function () {
                    _this2.logger.logInfo('Init liferay client DONE', _this2.options);
                    _this2._doNextState(_Constants.STATE.INITIALIZED);
                    _this2.trigger(_Constants.EVENTS.INIT);
                    return _this2._init = true;
                });
                if (this.options.autoSessionValidation) {
                    this.logger.logInfo('Start autoSessionValidation');
                    setInterval(function () {
                        return _this2._validateCurrentSession();
                    }, 10 * 1000);
                }
            }

            return this.initPromise;
        }
    }, {
        key: 'on',
        value: function on(event, handler) {
            if (!this.events[event]) this.events[event] = [];
            this.events[event].push(handler);
        }
    }, {
        key: 'off',
        value: function off(event, handler) {
            if (this.events[event]) {
                if (!handler) {
                    this.events[event] = [];
                } else {
                    var index = this.events[event].indexOf(handler);
                    if (index !== -1) {
                        this.events[event].splice(index, 1);
                    }
                }
            }
        }
    }, {
        key: 'trigger',
        value: function trigger(event, data) {
            this.logger.logInfo('Client trigger: ' + event, data);
            this.events[event] && this.events[event].forEach(function (handler) {
                return handler(data);
            });
        }
    }, {
        key: 'toString',
        value: function toString() {
            var session = this._getSession();
            return session ? 'Session: ' + (session.user.isGuest ? 'Guest' : this.user.firstName + ' ' + this.user.lastName) : 'No Session';
        }
    }, {
        key: '_validateCurrentSession',
        value: function _validateCurrentSession() {
            var _this3 = this;

            this.store.getObject(_Constants.SESSION_KEY).then(function (storedSession) {
                var session = _this3._getSession();
                if (session) {
                    if ((session.user && session.user.userId) !== (storedSession && storedSession.user && storedSession.user.userId)) {
                        _this3._refreshSession();
                    } else {
                        session = storedSession || session;
                        var refTime = _this3.options.refreshSessionAfter || 1000 * 60 * 60 * 12;
                        var diff = session.time ? new Date() - new Date(session.time) : 0;
                        _this3.logger.logDebug('Session ' + session.time + ' is ' + Math.round(diff / 1000) + 's old');
                        if (diff > refTime) {
                            //We add 5 seconds to prevent other tabs from concurrently refreshing
                            session.time = new Date(new Date(session.time).getTime() + 1000 * 5);
                            _this3.store.setObject(_Constants.SESSION_KEY, session);
                            _this3._refreshSession();
                        }
                    }
                }
            });
        }
    }, {
        key: '_validatePrevSession',
        value: function _validatePrevSession(previousSession) {
            var _this4 = this;

            if (previousSession && (previousSession.exception || !previousSession.user)) {
                this.logger.logError('previousSession is invalid! ', previousSession);
                previousSession = null;
            }

            if (previousSession && !(previousSession.user && previousSession.user.isGuest) && previousSession.url === this.options.url) {
                //isPreviousSessionValid()
                this.logger.logInfo('Remember previous session: ' + JSON.stringify(previousSession));
                this._doNextState(_Constants.STATE.REFRESH_PREVIOUS_SESSION_REQUESTED);
                var rememberSession = function rememberSession(session) {
                    _this4._setSession(session);
                    _this4._doNextState(_Constants.STATE.LOGGED_IN);
                };
                this._fetchSession(previousSession.hash).then(rememberSession).catch(function (_ref) {
                    var status = _ref.status;

                    if (status === 401) {
                        _this4._onNoValidPrevSession(previousSession);
                    } else {
                        //we cannot validate the session because of an unkown error. We believe the session is ok
                        _this4.logger.logError('cannot revalidate previous session', previousSession);
                        rememberSession(previousSession);
                    }
                });
            } else {
                this._onNoValidPrevSession(previousSession);
            }
        }
    }, {
        key: '_onNoValidPrevSession',
        value: function _onNoValidPrevSession(previousSession) {
            if (this.options.isSessionRequired) {
                if (previousSession) {
                    this.logger.logError('previous session invalid!', previousSession);
                }
                this.session = null;
                this._doNextState(_Constants.STATE.NO_SESSION);
            } else {

                if (this.options.sessionSharing && this._foundLRSessionCookie()) {
                    this._refreshSession();
                } else {
                    this._doNextState(_Constants.STATE.LOGGED_IN);
                }
            }
        }
    }, {
        key: '_setSession',
        value: function _setSession(session) {
            // let emailChanges = (this.session && this.session.user && this.session.user.emailAddress) != (session && session.user && session.user.emailAddress);
            this.session = session;
            if (session) {
                session.time = new Date();
            }
            this.store.setObject(_Constants.SESSION_KEY, session); //async but ok!
        }
    }, {
        key: '_getAccessToken',
        value: function _getAccessToken() {
            var _this5 = this;

            if (this.session && this.session.accessToken && new Date().getTime() + 2000 > this.session.exp) {
                this.logger.logInfo('Acccess token expired! Fetching new token.');
                return this._refreshSession().then(function () {
                    return _this5.session && _this5.session.accessToken;
                }).catch(Promise.reject);
            } else {
                return Promise.resolve(this.session && this.session.accessToken);
            }
        }
    }, {
        key: '_doNextState',
        value: function _doNextState(nextState) {
            var oldState = this.activeState;
            this.activeState = nextState;
            this._onStateChanged(this.activeState, oldState);
            this.trigger(_Constants.EVENTS.STATE_CHANGED, this.activeState);

            if (nextState === _Constants.STATE.LOGGED_IN && oldState !== _Constants.STATE.LOGGED_IN) {
                this.trigger(_Constants.EVENTS.LOGIN, this.getUser());
            }

            if (nextState === _Constants.STATE.NO_SESSION) {
                this.trigger(_Constants.EVENTS.NO_SESSION);
            }
        }
    }, {
        key: '_fetchSession',
        value: function _fetchSession(hash) {
            var _this6 = this;

            var authToken = !this.options.useBasicAuth && this._getAuthToken();

            this.logger.logInfo('_fetchSession ' + (this.options.useBasicAuth ? ' basicAuth ' : '') + (hash ? ' with auth hash' : 'without auth hash') + (authToken ? ' and auth token' + authToken : ''));

            var p = new Promise(function (resolve, reject) {
                var req = new XMLHttpRequest();
                req.onreadystatechange = function () {
                    if (req.readyState === 4 && req.status === 200) {
                        var session = (0, _utils.responseToJSON)(req.response);
                        var invalid = _this6._isInvalidSession(session);
                        if (!invalid) {
                            resolve(_extends({
                                url: _this6.options.url,
                                hash: hash
                            }, session));
                        } else {
                            reject(invalid);
                        }
                    } else if (req.readyState === 4) {
                        reject({ statusText: req.statusText, status: req.status, request: req });
                    }
                };
                req.onerror = function () {
                    reject({ statusText: req.statusText, status: req.status, request: req });
                };
                req.open('GET', _this6.options.url + _this6.options.loginServicePath + (authToken ? '?p_auth=' + authToken : ''), true);
                if (_this6.options.useBasicAuth && hash) {
                    req.setRequestHeader('Authorization', 'Basic ' + hash);
                }
                try {
                    req.responseType = 'json';
                } catch (exception) {
                    _this6.logger.logWarn('XMLHttpRequest.responseType = \'json\' not supported.');
                }
                req.send();
            });
            p.then(this.logger.logInfo.bind(null, '_fetchSession success'), this.logger.logError.bind(null, '_fetchSession fail'));
            return p;
        }
    }, {
        key: '_isInvalidSession',
        value: function _isInvalidSession(session) {
            if (!session) {
                return 'no session';
            }
            if (!session.user) {
                return 'no session user';
            }
            if (!this.options.guestIsValidSession && session.user.isGuest) {
                return 'guest session';
            }
            return null; //valid
        }
    }, {
        key: '_refreshSession',
        value: function _refreshSession() {
            var _this7 = this;

            if (!this._refreshPromise) {
                this.logger.logInfo('Refresh session'); //todo memorize pending requests
                this._refreshPromise = this._fetchSession(this._getAuthHash()).then(function (session) {
                    _this7._refreshPromise = null;
                    _this7._setSession(session);
                    if (_this7.getState() !== _Constants.STATE.LOGGED_IN) {
                        _this7._doNextState(_Constants.STATE.LOGGED_IN);
                    }
                }).catch(function (e) {
                    _this7._refreshPromise = null;
                    _this7.logger.logError('refresh session error', e);
                    _this7._setSession(null);
                    _this7._doNextState(_Constants.STATE.NO_SESSION);
                });
            } else {
                this.logger.logDebug('_refreshPromise exists!');
            }
            return this._refreshPromise;
        }
    }, {
        key: '_login',
        value: function _login(username, password) {
            var _this8 = this;

            this._doNextState(_Constants.STATE.SESSION_REQUESTED);
            var _hash = this.options.useBasicAuth && _base2.default.encode(username + ':' + password);
            return this._fetchSession(_hash).then(function (session) {
                _this8._setSession(session);
                _this8._doNextState(_Constants.STATE.LOGGED_IN);
            }).catch(function (e) {
                _this8._doNextState(_Constants.STATE.NO_SESSION);
                _this8.trigger(_Constants.EVENTS.LOGIN_FAIL, e);
                _this8.logger.logError(e);
            });
        }
    }, {
        key: '_logout',
        value: function _logout() {
            this.logger.logInfo('logout: ' + this._getSession());
            this._setSession(null);
            this._doNextState(_Constants.STATE.NO_SESSION);
        }
    }, {
        key: '_preValidateCredentials',
        value: function _preValidateCredentials(username, password) {
            if (!username || !password || username.length === 0 || password.length === 0) {
                return _Constants.MESSAGES.please_enter_credentials;
            }
            return null;
        }
    }, {
        key: '_invoke',
        value: function _invoke(path, data, headers) {
            var _this9 = this;

            return new Promise(function (resolve, reject) {
                try {
                    var reqData = data,
                        request = new XMLHttpRequest(),
                        invokePath = _this9.options.url + '/api/jsonws';

                    if (data instanceof FormData) {
                        _this9.logger.logInfo('LiferayService.post formdata: ' + path + ' (' + JSON.stringify(data, undefined, '  ') + ')');
                        invokePath += path;
                    } else {
                        _this9.logger.logInfo('LiferayService.invoke: ' + path + ' (' + JSON.stringify(data, undefined, '  ') + ')');
                        invokePath += '/invoke';
                        reqData = 'cmd=' + encodeURIComponent(JSON.stringify(_defineProperty({}, path, data || {})));
                    }

                    var error = function error(msg, exception) {
                        _this9.logger.logError(msg, exception, request);
                        reject({
                            error: new Error(msg),
                            exception: exception,
                            request: request
                        });
                    };

                    var inst = _this9;
                    request.onreadystatechange = function () {
                        if (this.readyState === 4 && this.status === 200) {
                            var json = (0, _utils.responseToJSON)(this.response);
                            if (json && json.error) {
                                error(json.error.message, json.error.type); //LR Throwable json @see JSONFactoryImpl
                            } else {
                                resolve(json, this.statusText);
                            }
                        } else if (this.readyState === 4) {
                            if (this.status === 401 || this.status === 403) {
                                inst.logger.logWarn("Auth error " + this.status + " trigger refresh session");
                                inst._refreshSession();
                            }
                            var _json = void 0;
                            try {
                                _json = this.response && (0, _utils.responseToJSON)(this.response);
                            } catch (e) {
                                inst.logger.logError(e);
                            }
                            if (_json && _json.error) {
                                error(_json.error.message, _json.error.type); //LR Throwable json @see JSONFactoryImpl
                            } else {
                                error(this.statusText);
                            }
                        }
                    };

                    request.onerror = function () {
                        error('XMLHttpRequest Error: ' + this.statusText);
                    };

                    if (!(_this9.options && _this9.options.useBasicAuth)) {
                        invokePath = invokePath + '?p_auth=' + _this9._getAuthToken();
                    }

                    request.open('POST', invokePath, true);
                    try {
                        request.responseType = 'json';
                    } catch (exception) {
                        _this9.logger.logWarn('XMLHttpRequest.responseType = \'json\' not supported.');
                    }
                    if (!(data instanceof FormData)) {
                        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                    }
                    _this9._setAuth(request);

                    if (headers) {
                        Object.keys(headers).forEach(function (key) {
                            request.setRequestHeader(key, headers[key]);
                        });
                    }
                    request.send(reqData);
                } catch (e) {
                    _this9.logger.logError(e);
                    reject(e);
                }
            });
        }
    }, {
        key: '_setAuth',
        value: function _setAuth(request) {
            if (this.options && this.options.useBasicAuth) {
                /* SESSION SHARING IS DISSABLED DO NOT CHANGE THIS!!! */
                request.withCredentials = false;
                var hash = this._getAuthHash();
                if (hash) {
                    if (request.setRequestHeader) {
                        request.setRequestHeader('Authorization', 'Basic ' + hash);
                    } else if (request.headers) {
                        request.headers.Authorization = 'Basic ' + hash;
                    }
                }
            } else {
                request.withCredentials = this.options.sessionSharing; //todo should this always be true?
            }
        }
    }, {
        key: '_getAuthHash',
        value: function _getAuthHash() {
            var session = this._getSession();
            return session && session.hash;
        }
    }]);

    return LiferayClient;
}();