'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Created by Nikolai Gorte
 * User: n.gorte on 14.09.2016
 * Copyright: xdot GmbH 2016
 */

var FileUploader = exports.FileUploader = function () {
    function FileUploader(client, uploadServiceUrl) {
        _classCallCheck(this, FileUploader);

        this.lrc = client;
        this.uploadServiceUrl = uploadServiceUrl;
    }

    _createClass(FileUploader, [{
        key: 'safeFileName',
        value: function safeFileName(uri) {
            //seperate filename from uri
            var fileName = uri.substr(uri.lastIndexOf('/') + 1);
            //remove additional fileparameter from uri
            var lastIndexOfQuestionMark = fileName.lastIndexOf('?');
            if (lastIndexOfQuestionMark > -1) {
                fileName = fileName.substring(0, lastIndexOfQuestionMark);
            }
            return fileName;
        }

        /**
         *
         * @param data uri/object/blob
         * @param url service url
         * @param params
         * @param o
         * @param onProgress
         * @returns {Promise<any>}
         */

    }, {
        key: 'fileUpload',
        value: function fileUpload(data, url, params, _ref) {
            var _this = this;

            var o = _ref.o,
                onProgress = _ref.onProgress;

            return new Promise(function (resolve, reject) {
                params = params || {};
                console.info('Upload: ' + data);

                function debugOut() {
                    return ' -> fileUpload(' + data + ', ' + url + ', params: ' + JSON.stringify(params) + ', ' + JSON.stringify(params) + ')';
                }

                function resultHandler(r) {
                    try {
                        var result = r.response && JSON.parse(r.response) || {};
                        if (result.exception) {
                            console.error('Upload error. Result: ' + r.response + debugOut());
                            reject(result.exception);
                        } else {
                            console.info('Upload ' + data + ' successful, response:' + r.response);
                            resolve(result);
                        }
                    } catch (e) {
                        console.error('Upload error - Error = ' + e.message + ' with result: ' + r.response + debugOut());
                        reject(e);
                    }
                }

                function progress(progressEvent) {
                    console.info('onprogress: ' + JSON.stringify(progressEvent));
                    if (onProgress) onProgress(progressEvent);
                }

                // Cordova
                if (typeof FileUploadOptions !== 'undefined') {
                    var fileUrl = data.uri;
                    var options = new FileUploadOptions();
                    options.fileKey = 'file';
                    options.fileName = data.name || _this.safeFileName(fileUrl);
                    options.mimeType = data.type || o && o.type || 'application/octet-stream';
                    options.headers = { Connection: 'close' }; //Temporary fix for CB-2293
                    options.params = params;
                    options = _extends({}, options, o);
                    _this.lrc._setAuth(options);

                    var ft = new FileTransfer();
                    ft.onprogress = progress;
                    ft.upload(fileUrl, url, function (result) {
                        resolve(JSON.parse(result.response));
                    }, function (e) {
                        console.error('Upload error: Code = ' + e.code + debugOut());
                        reject(e);
                    }, options);
                } else if (data instanceof Blob || typeof navigator !== 'undefined' && navigator.product === 'ReactNative' && data instanceof Object) {
                    // BLOB OR REACT NATIVE
                    console.info('Start XHR2 File upload!');
                    var oReq = new XMLHttpRequest();
                    oReq.open('POST', url, true);
                    oReq.onload = function (e) {
                        e.response = oReq.response;
                        resultHandler(e);
                    };

                    oReq.upload.addEventListener('progress', progress, false);
                    oReq.upload.addEventListener('error', function (e) {
                        console.error('Upload error ' + data + ' - : Loaded data = ' + e.loaded + debugOut());
                        reject(e);
                    }, false);

                    var formData = new FormData();
                    formData.append('file', data);
                    for (var k in params) {
                        formData.append(k, params[k]);
                    }

                    _this.lrc._setAuth(oReq);
                    oReq.send(formData);
                } else if ((data + '').indexOf('blob:') === 0 || (data + '').indexOf('moz-filedata:') === 0) {
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', data, true);
                    xhr.responseType = 'blob';
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            var blob = xhr.response;
                            var splitURL = data.split('/');
                            params.fileName = splitURL[splitURL.length - 1] + '.png';
                            console.log('BLOB', blob, url, params);
                            _this.fileUpload(blob, url, params, { o: o, onProgress: onProgress }).then(resolve, reject);
                        } else {
                            console.error('Blob upload ' + data + ' - fail! Status: ' + _this.status);
                            reject();
                        }
                    };

                    xhr.onerror = function (e) {
                        reject(e);
                    };
                    xhr.send();
                } else {
                    console.error('Unknown upload type: ', data, ' o:', JSON.stringify(o));
                    reject('Unknown upload type: ', data);
                }
            });
        }

        /**
         *
         * @param src - The source to upload (URL, Blob, File, Object{uri: String, type: String, name: String}
         * @params options
         * @param onProgress - Function
         * @return {*}
         */

    }, {
        key: 'upload',
        value: function upload(src, _ref2) {
            var options = _ref2.options,
                params = _ref2.params,
                onProgress = _ref2.onProgress;

            var ajaxUrl = this.lrc.getServerUrl() + '/api/jsonws' + this.uploadServiceUrl;
            return this.fileUpload(src, ajaxUrl, params, { o: options, onProgress: onProgress });
        }
    }]);

    return FileUploader;
}();