/**
 * User: n.gorte
 * Date: 26.03.2018
 * Time: 17:12
 */

'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CordovaPushPlugin = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _Constants = require('../Constants');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CordovaPushPlugin = exports.CordovaPushPlugin = function () {
    function CordovaPushPlugin(client) {
        var _this = this;

        _classCallCheck(this, CordovaPushPlugin);

        this.loadPushToken = function () {
            return _this.store.getItem('pushToken').then(function (pushToken) {
                return _this.pushToken = pushToken;
            });
        };

        this.getPushToken = function () {
            return _this.pushToken;
        };

        this.disablePush = function () {
            return _this.setPushToken(null);
        };

        this.isPushAlreadyRegistered = function () {
            return _this.pushAlreadyRegistered;
        };

        this.client = client;
        this.logger = client.logger;
        this.store = client.store;

        this.onPushNotification = this.onPushNotification.bind(this);
    }

    _createClass(CordovaPushPlugin, [{
        key: 'init',
        value: function init() {
            var _this2 = this;

            if (window.PushNotification) {
                this.interceptPushListener();
                var os = this.client.getDeviceInfo().family.toLowerCase();
                var pushConfig = this.client.options.deviceFamily;

                console.info('registering pushNotification for: ' + os + ' with config ' + JSON.stringify(pushConfig));
                this.push = window.PushNotification.init(pushConfig);
                this.push.on('registration', function (data) {
                    return _this2.setPushToken(data.registrationId);
                });
                this.push.on('notification', this.onPushNotification);
                this.push.on('error', function (err) {
                    _this2.logger.logError(err);
                    _this2.client.trigger(_Constants.EVENTS.PUSH_REGISTRATION_FAILED);
                });
            } else {
                this.client.logger.logError('No PushNotification Plugin found!');
            }
        }
    }, {
        key: 'clearBadges',
        value: function clearBadges() {
            this.push && this.push.setApplicationIconBadgeNumber(function () {
                console.info('REMOVED BADGES!');
            }, function () {
                console.error('COULD NOT REMOVE BADGES!');
            }, 0);
        }
    }, {
        key: 'setPushToken',
        value: function setPushToken(token) {
            var _this3 = this;

            if (this.getPushToken() !== token) {
                this.store.setItem('pushToken', token);
                this.pushToken = token;
                this.logger.logInfo('changed push token = ' + token);
                if (this.client.installPromise) {
                    this.client.installPromise.then(function () {
                        return _this3.client._install();
                    }).then(function () {
                        return _this3.client.trigger(_Constants.EVENTS.PUSH_REGISTERED);
                    });
                } else {
                    this.client.install().then(function () {
                        return _this3.client.trigger(_Constants.EVENTS.PUSH_REGISTERED);
                    });
                }
            } else {
                this.logger.logInfo('unchanged push token = ' + token);
            }
            this.pushAlreadyRegistered = !!token;
        }
    }, {
        key: 'onPushNotification',
        value: function onPushNotification(data) {
            // data.message,
            // data.title,
            // data.count,
            // data.sound,
            // data.image,
            // data.additionalData
            this.client.trigger(_Constants.EVENTS.NOTIFICATION, data);

            if (!this._foundPushListener) {
                this.logger.logInfo('No Push notification listner found! Notification is queued: ' + JSON.stringify(data));
                if (!this.pushQueue) {
                    this.pushQueue = [];
                } else if (this.pushQueue.length > 100) {
                    this.logger.logError('Max push notification queue size reached', this.pushQueue);
                    this.pushQueue.pop();
                }
                this.pushQueue.push(data);
            }
        }

        /**
         * Intercepts client.on('notification', cb) and to send queued push notifications to cb.
         */

    }, {
        key: 'interceptPushListener',
        value: function interceptPushListener() {
            var _this4 = this,
                _arguments = arguments;

            var orgOn = this.client.on;
            this.client.on = function (topic, listener) {
                orgOn.apply(_this4.client, [topic, listener]);
                try {
                    if (_arguments.length > 1 && _arguments[0] === 'notification') {
                        _this4._foundPushListener = true;
                        var q = _this4.pushQueue || [];
                        _this4.logger.logInfo('Detect notification listener: send ' + q.length + ' queued notifications');
                        for (var i = 0; i < q.length; i++) {
                            _arguments[1](null, q[i]);
                        }
                        _this4.pushQueue = [];
                    }
                } catch (e) {
                    _this4.logger.logError(e);
                }
            };
        }
    }]);

    return CordovaPushPlugin;
}();