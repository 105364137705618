'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var TraceKit = require('tracekit');

var ErrorLogger = exports.ErrorLogger = {
    // bind to global error event
    logConsoleErrors: function logConsoleErrors() {
        function onError(msg, url, lineNo, colNo, error) {
            var data = {
                message: msg,
                url: url,
                lineNo: lineNo,
                colNo: colNo,
                error: error || {}
            };
            data.stack = this.buildStackTrace(data);
            this.sendEvent(data);
        }

        try {
            window.onerror = onError.bind(this);
        } catch (e) {
            console.error(e);
        }
    },
    buildStackTrace: function buildStackTrace(e) {
        var stack = void 0;
        if (e.error) {
            // if window.onerror passed an error object
            stack = TraceKit.computeStackTrace(e.error);
        } else if (e.stack && e.message) {
            // error object (e.g. if buildStackTrace is called in try catch
            stack = TraceKit.computeStackTrace(e);
            e = {};
        } else {
            stack = null;
        }
        return stack;
    },
    sendEvent: function sendEvent(data) {
        var deviceInfo = this.getDeviceInfo && this.getDeviceInfo() || {};
        var appConfig = this.getAppConfig && this.getAppConfig() || {};
        var event = {
            variantVersionKey: appConfig.variantVersionKey || null,
            deviceUuid: deviceInfo.deviceUuid || null,
            platform: deviceInfo.platform || null,
            model: deviceInfo.model || null,
            osVersion: deviceInfo.version || deviceInfo.osVersion || null,
            data: JSON.stringify(data)
        };

        this.invokeMobile(this.options.errorLoggingMethod, event).then(function (data) {
            console.log('Result: ', data);
        });
    }
};