/**
 * User: n.gorte
 * Date: 26.03.2018
 * Time: 16:03
 */
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ReactNativeStorage = exports.ReactNativeStorage = function ReactNativeStorage(storage) {
    var _this = this;

    _classCallCheck(this, ReactNativeStorage);

    this.getItem = function (key) {
        return _this.storage.getItem(key);
    };

    this.setItem = function (key, v) {
        return _this.storage.setItem(key, v);
    };

    this.getObject = function (key) {
        return _this.getItem(key).then(function (v) {
            return JSON.parse(v);
        });
    };

    this.setObject = function (key, v) {
        return _this.setItem(key, JSON.stringify(v));
    };

    this.clear = function () {
        return _this.storage.clear();
    };

    this.removeItem = function (key) {
        return _this.storage.removeItem(key);
    };

    this.storage = storage; // React Native AsyncStorage
};