/**
 * User: n.gorte
 * Date: 08.08.2018
 * Time: 14:27
 */
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var SESSION_KEY = exports.SESSION_KEY = 'lfrsession';

var MESSAGES = exports.MESSAGES = {
    please_enter_credentials: 'Bitte geben Sie den Benutzernamen und das Passwort ein!',
    login_successful: 'Anmeldung erfolgreich!'
};

var DEFAULT_OPTIONS = exports.DEFAULT_OPTIONS = {
    loginServicePath: '/api/jsonws/js-client-portlet.authentication/get-access-info',
    isSessionRequired: true,
    useBasicAuth: true,
    sessionSharing: false,
    autoInstall: true,
    guestIsValidSession: true,
    refreshSessionAfter: 0, //refreshes the session if she is older than this (autoSessionValidation must be true)
    autoSessionValidation: false //Checks session in localstorage and triggers refreshSession (refreshSessionAfter).
};

var DEFAULT_MOBILE_OPTIONS = exports.DEFAULT_MOBILE_OPTIONS = {
    registerMethod: '/mobileapps.installation/register-installation.7',
    unregisterMethod: '/mobileapps.installation/unregister-installation.2',
    errorLoggingMethod: '/mobileapps.event/add.6',
    deviceFamily: {
        android: {
            senderID: null,
            variantUuid: null,
            variantSecret: null
        },
        ios: {
            variantUuid: null,
            variantSecret: null
        },
        wp8: {
            variantUuid: null,
            variantSecret: null
        },
        browser: {
            variantUuid: null,
            variantSecret: null
        }
    }
};

var STATE = exports.STATE = {
    INIT: 'INIT',
    INITIALIZED: 'INITIALIZED',
    NO_SESSION: 'NO_SESSION',
    REFRESH_PREVIOUS_SESSION_REQUESTED: 'REFRESH_PREVIOUS_SESSION_REQUESTED',
    SESSION_REQUESTED: 'SESSION_REQUESTED',
    LOGGED_IN: 'LOGGED_IN',
    SHOULD_INSTALL: 'SHOULD_INSTALL',
    INSTALL_REQUESTED: 'INSTALL_REQUESTED',
    INSTALLED: 'INSTALLED'
};

var EVENTS = exports.EVENTS = {
    STATE_CHANGED: 'stateChanged',
    NO_SESSION: 'noSession',
    INIT: 'init',
    INSTALL: 'install',
    PUSH_REGISTERED: 'pushRegistered',
    PUSH_REGISTRATION_FAILED: 'pushRegisteredFailed',
    NOTIFICATION: 'notification',
    LOGIN: 'login',
    LOGIN_FAIL: 'login_fail',
    LOGOUT: 'logout'
};