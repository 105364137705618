/**
 * User: l.einfeldt
 * Date: 03.05.2018
 * Time: 10:43
 */
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ReactNativePushPlugin = exports.ReactNativePushPlugin = function () {
    function ReactNativePushPlugin(client, pushNotification, PushNotificationIOS) {
        _classCallCheck(this, ReactNativePushPlugin);

        this.client = client;
        this.logger = client.logger;
        this.store = client.store;
        this.pushNotification = pushNotification;
        this.PushNotificationIOS = PushNotificationIOS;
    }

    _createClass(ReactNativePushPlugin, [{
        key: 'init',
        value: function init(pushConfigs) {
            var _this = this;

            var os = this.client.getDeviceInfo().family.toLowerCase();
            var pushConfig = pushConfigs[os];

            if (this.pushNotification) {
                console.info('registering pushNotification for: ' + os + ' with config ' + JSON.stringify(pushConfig));
                this.pushNotification.configure({
                    onRegister: function onRegister(token) {
                        _this.setPushToken(token.token);
                    },
                    onNotification: function onNotification(notification) {
                        _this.onPushNotification(notification);
                        notification.finish(_this.PushNotificationIOS.FetchResult.NoData);
                    },
                    senderID: pushConfig.senderID,
                    permissions: _extends({
                        alert: true,
                        badge: true,
                        sound: true
                    }, pushConfig.permissions),
                    popInitialNotification: pushConfig.popInitialNotification || true,
                    requestPermissions: pushConfig.requestPermissions || true
                });
            } else {
                this.client.logger.logError('No PushNotification Plugin found!');
            }
        }
    }, {
        key: 'loadPushToken',
        value: function loadPushToken() {
            var _this2 = this;

            this.store.getItem('pushToken').then(function (pushToken) {
                return _this2.pushToken = pushToken;
            });
        }
    }, {
        key: 'getPushToken',
        value: function getPushToken() {
            return this.pushToken;
        }
    }, {
        key: 'setPushToken',
        value: function setPushToken(token) {
            var _this3 = this;

            if (this.getPushToken() !== token) {
                this.store.setItem('pushToken', token);
                this.pushToken = token;
                this.logger.logInfo('changed push token = ' + token);
                if (this.client.installPromise) {
                    this.client.installPromise.then(function () {
                        return _this3.client.reInstall();
                    });
                } else {
                    this.client.install();
                }
            } else {
                this.logger.logInfo('unchanged push token = ' + token);
            }
            this.pushAlreadyRegistered = !!token;
        }
    }, {
        key: 'disablePush',
        value: function disablePush() {
            this.setPushToken(null);
        }
    }, {
        key: 'isPushAlreadyRegistered',
        value: function isPushAlreadyRegistered() {
            return this.pushAlreadyRegistered;
        }
    }, {
        key: 'onPushNotification',
        value: function onPushNotification(data) {
            // data.message,
            // data.title,
            // data.count,
            // data.sound,
            // data.image,
            // data.additionalData
            this.client.trigger('notification', data);

            if (!this._foundPushListener) {
                this.logger.logInfo('No Push notification listner found! Notification is queued: ' + JSON.stringify(data));
                if (!this.pushQueue) {
                    this.pushQueue = [];
                } else if (this.pushQueue.length > 100) {
                    this.logger.logError('Max push notification queue size reached', this.pushQueue);
                    this.pushQueue.pop();
                }
                this.pushQueue.push(data);
            }
        }

        /**
         * Intercepts client.on('notification', cb) and to send queued push notifications to cb.
         */

    }, {
        key: 'interceptPushListener',
        value: function interceptPushListener() {
            var _this4 = this,
                _arguments = arguments;

            var orgOn = this.client.on;
            this.on = function (topic, listener) {
                orgOn.apply(_this4, [topic, listener]);
                try {
                    if (_arguments.length > 1 && _arguments[0] == 'notification') {
                        _this4._foundPushListener = true;
                        var q = _this4.pushQueue || [];
                        _this4.logger.logInfo('Detect notification listener: send ' + q.length + ' queued notifications');
                        for (var i = 0; i < q.length; i++) {
                            _arguments[1](null, q[i]);
                        }
                        _this4.pushQueue = [];
                    }
                } catch (e) {
                    _this4.logger.logError(e);
                }
            };
        }
    }]);

    return ReactNativePushPlugin;
}();