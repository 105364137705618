/**
 * User: n.gorte
 * Date: 05.04.2019
 * Time: 09:51
 */
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var xhrResponseTypeSupport = exports.xhrResponseTypeSupport = function xhrResponseTypeSupport() {
    var xhr = new XMLHttpRequest();
    //xhr.open('get', '/', true);
    if ('responseType' in xhr) {
        try {
            xhr.responseType = 'json';
        } catch (exception) {
            console.warn("XMLHttpRequest.responseType = 'json' not supported.");
        }
        if (xhr.responseType === 'json') {
            return true;
        }
    }
};

var responseToJSON = exports.responseToJSON = xhrResponseTypeSupport() ? function (r) {
    return r;
} : function (r) {
    return JSON.parse(r);
};