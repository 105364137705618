'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DefaultDeviceInfo = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * User: n.gorte
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Date: 26.03.2018
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Time: 16:03
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */


var _clientDetection = require('./client-detection');

var _clientDetection2 = _interopRequireDefault(_clientDetection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DefaultDeviceInfo = exports.DefaultDeviceInfo = function () {
    function DefaultDeviceInfo() {
        var _this = this;

        _classCallCheck(this, DefaultDeviceInfo);

        this._deviceReady = new Promise(function (resolve, reject) {
            if (window.cordova) {
                document.addEventListener('deviceready', function () {
                    _this._isDeviceReady = true;
                    resolve();
                }, false);
            } else {
                _this._isDeviceReady = true;
                resolve();
            }
        });

        this.getDeviceReadyPromise = this.getDeviceReadyPromise.bind(this);
    }

    _createClass(DefaultDeviceInfo, [{
        key: 'isDeviceReady',
        value: function isDeviceReady() {
            return this._isDeviceReady;
        }
    }, {
        key: 'getDeviceReadyPromise',
        value: function getDeviceReadyPromise() {
            return this._deviceReady;
        }
    }, {
        key: 'checkDeviceReady',
        value: function checkDeviceReady() {
            if (!this.isDeviceReady()) {
                try {
                    throw new Error('Device not Ready');
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }, {
        key: 'guidGenerator',
        value: function guidGenerator() {
            var S4 = function S4() {
                return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
            };
            return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
        }
    }, {
        key: 'getDeviceUuid',
        value: function getDeviceUuid() {
            this.checkDeviceReady();
            var uuid = void 0;
            uuid = window.device && window.device.uuid;
            if (!uuid) {
                uuid = localStorage.getItem('browserUuid');
                if (!uuid) {
                    uuid = this.guidGenerator();
                    localStorage.setItem('browserUuid', uuid);
                }
            }
            return uuid;
        }
    }, {
        key: 'getDeviceInfo',
        value: function getDeviceInfo() {
            this.checkDeviceReady();
            var device = void 0;
            if (!this.device) {
                if (window.device) {
                    device = _extends({}, window.device, {
                        family: window.device.platform.toLowerCase() === 'win32nt' ? 'wp8' : window.device.platform,
                        osVersion: window.device.version,
                        deviceUuid: window.device.uuid
                    });
                } else {
                    if (window.cordova) {
                        console.error('No device plugin for cordova app: Use the Device Plugin or wait for mobileAppsReady()');
                    }

                    var cDetectData = (0, _clientDetection2.default)();
                    device = {
                        deviceUuid: this.getDeviceUuid(),
                        model: cDetectData.device.device,
                        platform: cDetectData.os.name + ' - ' + cDetectData.browser.name + ' ' + cDetectData.browser.version,
                        osVersion: cDetectData.os.version || cDetectData.os.versionString,
                        family: 'browser',
                        cordova: null
                    };
                }
                this.device = device;
            }

            return this.device;
        }
    }]);

    return DefaultDeviceInfo;
}();