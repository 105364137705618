/**
 * User: n.gorte
 * Date: 26.03.2018
 * Time: 16:03
 */
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ReactNativeDeviceInfo = exports.ReactNativeDeviceInfo = function ReactNativeDeviceInfo(DeviceInfo) {
    var _this = this;

    _classCallCheck(this, ReactNativeDeviceInfo);

    this.getDeviceReadyPromise = function () {
        return Promise.resolve();
    };

    this.buildDeviceInfo = function () {
        return {
            deviceUuid: _this.DeviceInfo.getUniqueID(),
            model: _this.DeviceInfo.getModel(),
            platform: _this.DeviceInfo.getSystemName(),
            family: _this.DeviceInfo.getSystemName(),
            osVersion: _this.DeviceInfo.getSystemVersion()
        };
    };

    this.getDeviceInfo = function () {
        return _this.device || _this.buildDeviceInfo();
    };

    this.DeviceInfo = DeviceInfo; // e.g https://github.com/rebeccahughes/react-native-device-info#getreadableversion
    this.device = this.buildDeviceInfo();
    this.getDeviceReadyPromise = this.getDeviceReadyPromise.bind(this);
}

// no device ready event in react native
;